.past-trips-card{
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(194, 194, 194);
    height: 245px;
    width: 820px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 40px;
}

.trips-review {
    /* margin-top: 10px; */
}