.create-spot-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-left: 25px;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 30px;
}

.spot-x {
    position: absolute;
    left: 25px;
    top: 20px;
    cursor: pointer;
    color: #404040;
}

.spot-x:hover {
    color: #e61e4d;
}

.create-spot-title {
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: 400;
    font-size: 22px;
}

.create-container {
    height: 760px;
    width: 660px;
    overflow: auto;
    /* overflow-y: auto; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    top: -4.2pc;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -42%);
    padding-bottom: 20px;
}

.create-form {
    padding-left: 55px;
    padding-right: 55px;
    display: flex;
    flex-direction: column;
}

.create-label-container {
    border-radius: 8px;
}

.create-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
}

.address, .city, .state, .country, .name, .price, .previewImage {
    font-size: 16px;
    padding: 20px;
    padding-left: 0px;
    padding-bottom: 15px;
    border: none;
    border-radius: 3px;
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.description {
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    padding: 20px;
    padding-left: 0px;
    padding-bottom: 45px;
    border: none;
    border-radius: 3px;
    resize: none;
}

.listings-button {
    background: linear-gradient(to left, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    margin-top: 25px;
    touch-action: manipulation;
    margin-top: 25px;
    width: 100%;
}

.listings-button:hover {
    background: linear-gradient(to right, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
}



