.spots-home {
    padding-left: 10px;
    padding-right: 10px;
    /* background-color: black; */
}

.spots-wrapper {
    max-width: 1980px;
    margin-left: auto;
    margin-right: auto;

}

.spot-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 15px;
    border-radius: 5px;
    padding-bottom: 10px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
}

.nav-link {
    flex-basis: calc(100% / 6);
    box-sizing: border-box;
    padding: 0 12.5px;
    margin-bottom: 25px;
    text-decoration: none;
    color: black;
}


@media screen and (max-width: 2304px) {
    .spot-list-container {
        margin-right: auto;
        margin-left: auto;
    }
}


@media screen and (max-width: 1920px) {
    .spot-list-container {
        margin-right: auto;
        margin-left: auto;
    }
}

@media screen and (max-width: 1200px) {
    .nav-link {
        flex-basis: calc(100% / 4);
    }
}

@media screen and (max-width: 900px) {
    .nav-link {
        flex-basis: calc(100% / 3);
    }
}

@media screen and (max-width: 600px) {
    .nav-link {
        flex-basis: calc(100% / 2);
    }
}


.spot-card {
    max-width: 290px;
}

.home-page-spot-info {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
}


.spot-location-rating {
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
}

.spot-city-state {
    font-family: 'Roboto', sans-serif;
    /* padding-top: 5px; */
    font-weight: 350;
}

.home-rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-star {
    margin-right: 4px;
    font-size: 12px;
}

.avg-rating {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.spot-name {
    margin-top: 3px;
    margin-bottom: 7px;
    font-weight: 300;
    color: rgb(77, 77, 77);
}

.spot-price {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
}

.spot-price,
.night {
    display: inline-block;
    padding-top: 2px;
}

.night {
    margin-left: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 200;
}

.nav-link.active {
    color: transparent;
    text-decoration: none;
}

.spot-image {
    /* width: 100%; */
    max-width: 290px;
    max-height: 290px;
    object-fit: cover;
    border-radius: 10px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    aspect-ratio: 20/19;
    overflow: hidden;
    border-radius: 12px;
}

.swiper-pagination-bullet-active {
    background: white !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet {
    opacity: 0.6;
    background: white;
    transform: scale(0.83333);
    margin: 12px 2px !important;
}

.swiper-button-prev,
.swiper-button-next {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    margin: 0;
    background-clip: padding-box;
    background-color: rgba(255, 255, 255, 0.9);
    border-color: rgba(0, 0, 0, 0.08);
    transition: transform 0.25s ease;
    color: #222;
    touch-action: manipulation;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-prev:after {
    font-size: 13px;
    margin-right: 2px;
    font-weight: 700;
}

.swiper-button-next:after {
    margin-left: 2px;
    font-weight: 700;
    font-size: 10px;
}