.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100vw; */
    height: 100%;
}

.spot-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    min-height: 100vh;
}

.spot-title {
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}

.reviews-location {
    font-family: 'Roboto', sans-serif;
    padding-bottom: 18px;
}

.image-details-grid {
    --gap: 8px;
    --num-cols: 4;
    --row-height: 270px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);
}


.image-details-grid>img {
    height: 100%;
    width: 100%;
}

.image-grid-col-2 {
    grid-column: span 2;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
}

.image-grid-row-2 {
    grid-row: span 2;
}

.non-preview-img-2 {
    border-top-right-radius: 13px;
}

.non-preview-img-4 {
    border-bottom-right-radius: 13px;
}

#top-right-img {
    border-top-right-radius: 12px;
}

#bottom-right-img {
    border-bottom-right-radius: 12px;
}

@media screen and (max-width: 1024px) {
    .image-grid {
        --num-cols: 2;
        --row-height: 200px;
    }
}

.description-bookings {
    display: flex;
    flex-direction: row;
}

.spot-description-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 60%;
    flex-basis: 60%;
}

.info {
    line-height: 1.25;
}

.listing-info-container {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 25px;
}

.hosting-info {
    padding-top: 30px;
    font-weight: 400;
    font-size: 23px;
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;;
}

.listing-info {
    padding-top: 8px;
    font-size: 17px;
    font-weight: 300;
}

.hosting-info-container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 30px;
}


.fa-calendar {
    font-size: 22px;
    padding-right: 10px;
}

.info-workspace {
    font-size: 22px;
    padding-right: 8px;
}

.fa-circle-user {
    font-size: 22px;
    padding-right: 8px;
    padding-bottom: 3px;

}

.check-in,
.work-space,
.super-host {
    font-weight: 600;
}


.check-in-info,
.super-host-info {
    padding-top: 3px;
    padding-bottom: 20px;
    font-weight: 200;
    padding-left: 30px;
    line-height: 1.5;
    color: rgb(113, 113, 113);
}

.air-cover-container {
    padding-top: 25px;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 20px;
}

.air-cover-title {
    display: flex;
}

.cover-air::before {
    content: "venture";
    color: #FF385C;
}

.cover-air::after {
    content: "cover";
    color: #000000;
}

.cover-air {
    font-size: 40px;
    font-weight: bold;
    margin: 0;
}

.air-cover-desc {
    padding-bottom: 20px;
    font-weight: 200;
    padding-top: 12px;

}

.spot-description {
    font-weight: 200;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc;
    line-height: 1.5;
}

.offers-container {
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
}

.offer-column1 {
    width: 50%;
    text-align: left;
    padding-right: 0;
}

.offer-column2 {
    width: 50%;
    text-align: left;
    padding-left: 110px;
}


.offer-item {
    margin-bottom: 10px;
}

.offer-symbol {
    font-weight: 200;
    /* margin-right: 10px; */
}

.offer-description {
    padding-top: 10px;
    width: 100%;
    font-weight: 200;
}

.fa-utensils,
.fa-tv,
.fa-car-side,
.fa-wind,
.fa-wifi,
.fa-utensils,
.fa-shower,
.fa-suitcase-medical,
.fa-soap {
    padding-right: 10px;
    padding-top: 7px;
    color: rgb(97, 97, 97);
}

.rating-button-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 50px;
    border-top: 1px solid #dcdcdc;
    margin-bottom: 35px;
}

.num-reviews {
    font-size: 1em;
    font-size: 21px;
}

.review-button {
    margin-top: 13px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 17px;
    color: rgb(80, 80, 80);
    text-decoration: underline;
    font-weight: 200;
}

.edit-delete-review {
    display: flex;
}

.delete-review-button {
    margin-top: 13px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 17px;
    color: rgb(80, 80, 80);
    text-decoration: underline;
    font-weight: 200;
}

.edit-review-button {
    margin-top: 13px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 17px;
    color: rgb(80, 80, 80);
    text-decoration: underline;
    font-weight: 200;
}

.review-button:hover {}



.user-reviews-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
}

.reviews-wrap {
    width: 50%;
    box-sizing: border-box;

}

.user-reviews {
    padding-right: 8rem;
    box-sizing: border-box;
    font-weight: 200;
    width: 100%;
    word-wrap: break-word;
    line-height: 1.5;
    white-space: pre-line;
    text-align: left;
    margin-bottom: 25px;

}

.user-ratings {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.review-modal-container {
    height: 410px;
    width: 520px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    transform: translate(-50%, -70%);

}

.review-title {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dbd6d6;
    padding-bottom: 20px;
    margin-top: 27px;
    font-weight: bold;
    font-size: 20px;
}

.reviews-form {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    color: black;
}

.reviews-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reviews {
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    resize: none;
    margin-bottom: 10px;
    padding: 15px 0 80px;
    padding-left: 20px;
    border: 1px solid rgba(151, 150, 150, 0.6);
    border-radius: 3px;
}


.stars {
    margin-top: 7px;
    margin-bottom: 10px;
    padding: 13px 0 13px;
    padding-left: 20px;
    border: 1px solid rgba(151, 150, 150, 0.6);
    border-radius: 3px;
}

.review-button-container {
    display: flex;
    justify-content: center;
}

.submit-review-button {
    background: linear-gradient(to left, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    width: 30rem;
    margin-top: 25px;
    touch-action: manipulation;
}

.submit-review-button:hover {
    background: linear-gradient(to right, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
}