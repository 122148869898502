.listings-card {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(194, 194, 194);
    height: 255px;
    width: 820px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 50px;
}

.listings-img {
    height: 255px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 50%;
    filter: brightness(0.7);
    transition: filter 0.2s ease;
    cursor: pointer;
}

.listings-btns {
    margin-top: 7px;
}

.delete-listing-btn {
    background-color: white;
    border: none;
    background-color: rgb(255, 255, 255);
    color: black;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 8px 0;
    width: 180px;
    font-size: 17px;
}

.delete-listing-btn:hover {
    background-color: #f7f7f7;
}

.edit-listing-btn {
    background-color: transparent;
    border: none;
    color: black;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 8px 0;
    width: 130px;
    font-size: 17px;
}

.edit-icon {
    margin-right: 5px;
    font-size: 20px;
}

.edit-listing-btn:hover {
    background-color: #f7f7f7;
}

.listings-img:hover {
    filter: brightness(1);
}