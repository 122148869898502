.signup-title {
    font-size: 20px;
    font-family: 'Roboto', sans-serif
}
.signup-container {
    height: 590px;
    width: 560px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.signup-form {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
}

.signup-label-container { 
    border: 1px solid #9d9d9d;
    border-radius: 8px;
}

.sign-up-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
}

.signup-input {
    font-size: 16px;
    padding: 20px;
    padding-bottom: 15px;
    border: none;
    /* width: 467px; */
    border-radius: 8px;
}

.signup-button {
    background: linear-gradient(to left, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    margin-top: 25px;
    touch-action: manipulation;
}

.signup-button:hover {
    background: linear-gradient(to right, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
}

