.modal-title {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dbd6d6;
    padding-bottom: 20px;
    margin-top: 27px;
    margin-bottom: 27px;
    font-weight: bold;
}

.venturebnb-title {
    margin-left: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-top: 27px;
    font-size: 22px;
}

.login-container {
    height: 540px;
    width: 560px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.login-form {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
}

.login-label-container {
    border: 1px solid #9d9d9d;
    border-radius: 8px;
}

.login-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
}

.user-email,
.password {
    font-size: 16px;
    padding: 20px;
    padding-bottom: 15px;
    border: none;
    border-radius: 8px;
}

.border {
    border: 1px solid #c7c7c7;
}

.login-button {
    background: linear-gradient(to left, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    margin-top: 25px;
    touch-action: manipulation;
}

.login-button:hover {
    background: linear-gradient(to right, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
}

.alternate-logins {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-weight: 200;
    padding: 0 10px;

}

.alternate-logins::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgb(221, 221, 221);
    margin-right: 16px;
}

.alternate-logins::after {
    content: "";
    /* display: block; */
    width: 100%;
    height: 1px;
    background-color: rgb(221, 221, 221);
    margin-left: 16px;
}

.modal-demo-button {
    margin-top: 15px;
    background: transparent;
    border-radius: 8px;
    border-width: 1px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    margin-top: 20px;
    touch-action: manipulation;
}

.modal-demo-button:hover {
    background-color: rgb(246, 246, 246);
}

.signup-modal-menu {
    margin-top: 52px;
    display: flex;
    justify-content: center;
}

.signup-modal-menu::before {
    content: "Don't have an account yet?";
    font-weight: 200;
}

.signup-modal-menu::after {
    margin-left: 10px;
    content: "Sign up";
    color: red;
    cursor: pointer;
}

.signup-modal-menu:hover::after {
    text-decoration: underline;
}