.hidden {
    display: none;
}

.nav-logo {
    max-height: 24px;
}

.nav-container {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    background-color: #fdfdfd;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
    /* flex-wrap: wrap; */z-index: 20;
}

.home-button {
    padding-left: 35px;
    flex-grow: 1;
    float: left;
}

.nav-bar-right-section {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
}

.create-spot-button {
    margin-right: 10px;
    box-sizing: border-box;
    border-radius: 20px;
    float: right;
}

.create-spot-navlink {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    font-size: 16px;
    padding: 12px;
    color: black;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.create-spot-navlink:hover {
    background-color: rgb(240, 239, 239);
    border-radius: 30px;
}

.profile-button {
    padding-right: 35px;
    box-sizing: border-box;
    border-radius: 20px;
    float: right;
}

.fa-folder-open {
    cursor: pointer;
    font-size: 25px;
    color: #ff385c;
    margin-right: 10px;
}

.nav-icons {
    cursor: pointer;
    font-size: 25px;
    margin-right: 15px;
    color: #ff385c;
}

.fa-github {
    padding-left: 20px;
}

.fa-folder-open:hover,
.nav-icons:hover {
    color: #ff6980;
    transform: scale(1.2); 
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
}