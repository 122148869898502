.profile-wrapper {
    position: relative;
    display: inline-block;
}

.open-menu-button {
    cursor: pointer;
    border-radius: 23px;
    box-shadow: none;
    border: 1px solid rgba(223, 223, 223, 1);
    padding-right: 13px;
    padding-left: 13px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: white;
}

.open-menu-button:hover {
    /* background-color: #ededed; */
    box-shadow: 0px 0px 2px #979797;
}

.profile-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    display: none;

}

.profile-dropdown.show {
    display: block;
    z-index: 1;
}

.user-container {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    white-space: nowrap;

}

.modal-container {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    white-space: nowrap;
    /* width: max-content; */
}

.email-button,
.linkedin-button,
.my-listings-button,
.trips-button,
.logout-button {
    display: flex;
    justify-content: start;
    align-items: center;
    border: none;
    width: 100%;
    padding-left: 20px;
    padding-right: 120px;
    background-color: transparent;
    height: 40px;
}

.email-button,
.linkedin-button {
    font-weight: bold;
    font-size: 14px;
}

.my-listings-button,
.trips-button,
.logout-button {
    font-size: 14px;
    font-weight: 200;
}

.linkedin-border {
    border-bottom: 1px solid #dbd6d6;
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal-button,
.demo-user-button {
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 350;
    display: flex;
    justify-content: start;
    align-items: center;
    border: none;
    width: 100%;
    padding-left: 20px;
    padding-right: 120px;
    background-color: transparent;
    height: 40px;
}

.dropdown-title {
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    font-size: 14px;
    font-weight: 350;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;

}

.logout-button,
.my-listings-button,
.trips-button,
.linkedin-button,
.modal-button,
.demo-user-button {
    cursor: pointer;
}

.profile-dropdown button:hover {
    background-color: #eaeaea;

}

.fa-user-circle {
    color: rgba(6, 5, 5, 0.587);
    font-size: 25px;
}

.fa-solid.fa-bars {
    padding-right: 7px;
    padding-bottom: 4px;
    font-size: 15px;
}

.fa-solid {
    font-size: 18px;
}